.c-sidebar {
  background: $primary;
  color: $heather;
}

.c-sidebar.c-sidebar-lg {
  width: $sidebar-size;
  flex: 0 0 $sidebar-size;
}

.c-sidebar-minimized {
  .logo-app {
    visibility: hidden;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: $sidebar-size;
  }
  .c-sidebar-minimized.c-sidebar-fixed {
    z-index: 1031;
    width: $sidebar-mini-size;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: $sidebar-mini-size;
  }
}

.c-sidebar {
  .c-sidebar-brand {
    flex: 0 0 50px;
  }
}

.c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(-180deg);
}

.c-sidebar + .c-wrapper {
  margin-left: $sidebar-size;
}

.c-sidebar-minimized + .c-wrapper {
  margin-left: $sidebar-mini-size;
}

.c-sidebar-minimized .c-sidebar-nav {
  padding-bottom: 50px;
  overflow: visible !important;
}

.c-sidebar-minimized.c-sidebar-fixed {
  z-index: 1031;
  width: $sidebar-mini-size;
}

.c-sidebar.c-sidebar-dark.c-sidebar-fixed.c-sidebar-lg.c-sidebar-lg-show,
.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-minimized.c-sidebar-lg {
  margin-left: 0 !important;
}

.c-sidebar-minimized .c-sidebar-nav-link,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle {
  overflow: hidden;
  white-space: nowrap;
  border-left: 0;
}
.c-sidebar-minimizer {
  display: none;
}

@media (min-width: 576px) and (max-width: 767px) {
  .toggleIcon {
    display: none;
  }
  .c-sidebar-minimizer {
    display: flex;
    &::before {
      display: block;
    }
  }
}

@media (max-width: 575px) {
  .toggleIcon {
    display: none;
  }
  .c-sidebar-minimizer {
    display: flex;
    &::before {
      display: block;
    }
  }
  .c-sidebar-lg-show:not(.c-sidebar-minimized) + .c-wrapper {
    .c-header {
      ul.c-header-nav {
        & > div {
          > img {
            display: none;
          }
        }
      }
    }
  }
  .btn-large {
    margin-right: 0;
    width: 100% !important;
    margin-top: 10px;
  }
}

.c-sidebar {
  background: $primary;
  color: $heather;
}

.c-sidebar.c-sidebar-lg {
  width: $sidebar-size;
  flex: 0 0 $sidebar-size;
}
.c-sidebar-minimized {
  .logo-app {
    visibility: hidden;
    opacity: 0;
  }
}

@media (min-width: 992px) {
  html:not([dir='rtl']) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir='rtl']) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: $sidebar-size;
  }
  .c-sidebar-minimized.c-sidebar-fixed {
    z-index: 1031;
    width: $sidebar-mini-size;
  }
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper,
  html:not([dir='rtl'])
    .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
    ~ .c-wrapper {
    margin-left: $sidebar-mini-size;
  }
}

.c-sidebar {
  .c-sidebar-brand {
    flex: 0 0 50px;
  }
}

.c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(-180deg);
}

.c-sidebar + .c-wrapper {
  margin-left: $sidebar-size;
}

.c-sidebar-minimized + .c-wrapper {
  margin-left: $sidebar-mini-size;
}

.c-sidebar-minimized .c-sidebar-nav {
  padding-bottom: 50px;
  overflow: visible !important;
}

.c-sidebar-minimized.c-sidebar-fixed {
  z-index: 1031;
  width: $sidebar-mini-size;
}

.c-sidebar.c-sidebar-dark.c-sidebar-fixed.c-sidebar-lg.c-sidebar-lg-show,
.c-sidebar.c-sidebar-dark.c-sidebar-lg-show.c-sidebar-fixed.c-sidebar-minimized.c-sidebar-lg {
  margin-left: 0 !important;
}

.c-sidebar-minimized .c-sidebar-nav-link,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle,
.c-sidebar-minimized .c-sidebar-nav-dropdown-toggle {
  overflow: hidden;
  white-space: nowrap;
  border-left: 0;
}

@media (min-width: 576px) and (max-width: 767px) {
  .toggleIcon {
    display: none;
  }
  .c-sidebar-minimizer {
    display: flex;
    &::before {
      display: block;
    }
  }
}

@media (max-width: 575px) {
  .toggleIcon {
    display: none;
  }
  .c-sidebar-minimizer {
    display: flex;
    &::before {
      display: block;
    }
  }
  .c-sidebar-lg-show:not(.c-sidebar-minimized) + .c-wrapper {
    .c-header {
      ul.c-header-nav {
        & > div {
          > img {
            display: none;
          }
        }
      }
    }
  }
  .btn-large {
    margin-right: 0;
    width: 100% !important;
    margin-top: 10px;
  }
}

.c-sidebar-minimized {
  .logo-text {
    font-size: 0;
    &::first-letter {
      font-size: 2rem;
    }
  }
}

.c-sidebar-minimized {
  .logo-text-auth {
    display: none;
    margin: 0;
  }
}

.c-sidebar-minimized .c-sidebar-nav > li.c-sidebar-nav-dropdown:last-child:hover .c-sidebar-nav-dropdown-items {
  max-height: calc(100vh - 425px) !important;
  overflow-y: overlay !important;
  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c4c4c4;
  }
}
