// Other styles
body {
  overflow-y: hidden;
  word-break: break-word;
  word-wrap: break-word;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.no-wrap {
  flex-wrap: no-wrap !important;
}

.btn {
  padding: 0.25rem 0.5rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-right: $mr-1;
  min-width: 130px;
  min-height: 36px;
  transition: 0.7s;
  .mr-icon {
    margin-right: $mr-icon;
  }
}

.btn-large {
  @extend .btn;
  min-width: 215px;
  min-height: 40px;
}

.btn-tetradic {
  color: $white;
  background-color: $tetradic;
  border-color: $tetradic;
}

.btn-blue {
  color: $white;
  background-color: $blue;
  border-color: $blue;
}

.btn-dark-blue {
  color: $white !important;
  background-color: $dark-blue !important;
  border-color: $dark-blue !important;
}

.btn-white {
  color: $torea;
  background-color: $white;
  border-color: $torea;
  &:hover {
    color: $white;
    background-color: $torea;
    border-color: $white;
  }
}

.btn-torea {
  color: $white;
  background-color: $torea;
  border-color: $white;
  &:hover {
    color: $torea;
    background-color: $white;
    border-color: $torea;
  }
}

.ant-btn:hover {
  opacity: 0.8;
}

.text-black {
  color: $black;
}

.c-body {
  max-height: calc(100vh - 100px);
  overflow: auto;
  background: $bg-color;
  .c-main {
    padding-top: 20px;
    .ant-radio {
      .ant-radio-inner {
        border-color: $torea;
        width: 25px;
        height: 25px;
        &::after {
          background-color: $torea;
          width: 12.5px;
          height: 12.5px;
          top: 5px;
          left: 5px;
          border-radius: 50%;
        }
      }
    }
    .table-responsive {
      margin-bottom: 22px;
      table.table {
        margin-bottom: 0;
      }
    }
    table.table {
      margin-bottom: 22px;
      font-size: 12px;
      & > thead {
        th {
          border-bottom: 1px solid #b0b0b0;
        }
      }
      &.table-bordered {
        th,
        td {
          border: 1px solid;
          border-color: #d7d7d7;
        }
      }
      th {
        white-space: nowrap;
        font-size: 12px;
        font-weight: bold;
      }
      tr {
        white-space: nowrap;
        font-size: 12px;
      }
    }
    .dropdown-operator {
      .ant-btn-default {
        color: $black !important;
        &:not(.ant-dropdown-trigger) {
          display: none;
        }
      }
    }
    .table-responsive {
      &::-webkit-scrollbar {
        width: 12px;
        height: 10px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #c4c4c4;
      }
    }
    @media (max-width: 575px) {
      width: 100%;
      margin-top: 10px;
      margin-right: 0;
      margin-bottom: 0.2em;
    }
  }
  &::-webkit-scrollbar {
    width: 12px;
    height: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c4c4c4;
  }
}

.c-header {
  background: $dark-blue;
  color: $white;
  min-height: 0;
  height: 50px;
  border: none;
  &,
  .c-header-nav {
    min-height: 0;
    max-height: 50px;
  }
  .c-header-nav-link {
    padding-right: 4px;
  }
  .toggleIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 20px;
    font-size: $font-size-icon-nav;
  }
  .greeting {
    display: inline-block;
  }
  .ant-dropdown-trigger > .anticon.anticon-down {
    vertical-align: middle !important;
  }
  @media (max-width: 575px) {
    .greeting {
      display: none !important;
    }
  }
}

.header {
  margin-top: 9px;
  margin-bottom: 30px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: -11px;
    left: 5px;
    right: 0;
    border-bottom: 1px solid #c3c6dd;
    height: 16px;
  }
}

.title {
  font-size: $font-size-md;
  font-weight: bold;
}

.nav-tabs {
  border: 1px solid;
  border-color: $periwinkle;
  background: $whisper;
  color: $black;
  .nav-item {
    margin-bottom: 0;
    .nav-link {
      border: none;
      border-radius: 0;
      border-right: 1px solid $periwinkle;
      color: $black;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.active {
        background: $endeavour;
      }
    }
  }
}

.modal-title {
  color: $black;
}

.ant-pagination {
  width: fit-content;
  background: $white;
  border: 1px solid $gray-light;
  border-radius: 5px;
  margin-left: auto !important;
  font-size: 12px !important;
  > li {
    > a:not([disabled]) {
      color: #828282;
    }
  }
  .ant-pagination-item {
    border: 0;
    border-right: 1px solid $gray-light;
    border-radius: 0;
    &:hover {
      border-color: $gray-light;
    }
  }
  .ant-pagination-item-active {
    background: $torea;
    > a {
      color: $white;
    }
    &:hover {
      border-color: $torea;
      > a {
        color: $white;
      }
    }
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0;
  }
  .ant-pagination-prev {
    border-right: 1px solid $gray-light;
    border-radius: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 49px;
    height: 30px;
  }
}

.anticon-swap-right {
  display: none !important;
}

.ant-picker-separator:after {
  content: '-';
}

.ant-notification.ant-notification-topRight {
  right: -3px !important;
  top: 5px !important;
  z-index: 9999;
}

.logo-app {
  font-size: $font-size-xl;
  transition: none 0.4s;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.c-main {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

.ml-auto {
  margin-left: auto;
}

.date-picker {
  background: $white;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  width: 100%;
  height: 32px;
  padding: 0 11px;
  &:hover {
    cursor: pointer;
    border-color: $dark-blue;
  }
  &:focus {
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(13, 32, 207, 0.2);
    outline: 0;
  }
}

.require-field {
  padding: 0 0.75rem 0.75rem;
  span {
    color: $sunset-orange;
    font-size: $font-size-sm;
  }
}

.ant-btn-icon-only.ant-dropdown-trigger {
  background: transparent;
  border: none;
  color: $black !important;
}

.operator {
  box-shadow: 0px 4px 4px #00000040;
  min-width: 100px;
  padding: 0 12px;
  background: white;
  .ant-btn {
    display: flex;
    align-items: center;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding: 5px 0;
    > span {
      font-size: 12px !important;
    }
    > svg {
      margin-right: 4px;
    }
  }
  > button:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.pointer {
  cursor: pointer;
}

.operator-item {
  svg {
    cursor: pointer;
    margin-right: 20px;
    font-size: 20px;
  }
}

.text-elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.text-center {
  text-align: center;
}

.icon-lg {
  font-size: $font-size-xl;
  cursor: pointer;
}

.icon-md {
  font-size: $font-size-lg;
  cursor: pointer;
}

.icon-sm {
  font-size: $font-size-normal;
  cursor: pointer;
}

//Responsive
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 575px) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (min-width: 768px) and (max-width: 1024px) {
      @content;
    }
  }
}

// start custom padding
@mixin p($top, $right, $bottom, $left) {
  padding-top: $top + px !important;
  padding-right: $right + px !important;
  padding-bottom: $bottom + px !important;
  padding-left: $left + px !important;
}

@mixin pl($left) {
  padding-left: $left + px !important;
}

@mixin pr($right) {
  padding-right: $right + px !important;
}

@mixin pb($bottom) {
  padding-bottom: $bottom + px !important;
}

@mixin pt($top) {
  padding-top: $top + px !important;
}

@mixin ptb($top, $bottom) {
  padding-top: $top + px !important;
  padding-bottom: $bottom + px !important;
}

@mixin plr($left, $right) {
  padding-left: $left + px !important;
  padding-right: $right + px !important;
}

@for $i from 0 to 100 {
  .p-#{$i} {
    @include pt($i);
    @include pr($i);
    @include pl($i);
    @include pb($i);
  }
  .pt-#{$i} {
    @include pt($i);
  }
  .pr-#{$i} {
    @include pr($i);
  }
  .pb-#{$i} {
    @include pb($i);
  }
  .pl-#{$i} {
    @include pl($i);
  }
  .plr-#{$i} {
    @include pl($i);
    @include pr($i);
  }
  .ptb-#{$i} {
    @include pt($i);
    @include pb($i);
  }
}

// end custom padding
// start custom margin
@mixin m($top, $right, $bottom, $left) {
  margin-top: $top + px !important;
  margin-right: $right + px !important;
  margin-bottom: $bottom + px !important;
  margin-left: $left + px !important;
}

@mixin mt($top) {
  margin-top: $top + px !important;
}

@mixin ml($left) {
  margin-left: $left + px !important;
}

@mixin mr($right) {
  margin-right: $right + px !important;
}

@mixin mb($bottom) {
  margin-bottom: $bottom + px !important;
}

@mixin mtb($top, $bottom) {
  margin-top: $top + px !important;
  margin-bottom: $bottom + px !important;
}

@mixin mlr($left, $right) {
  margin-left: $left + px !important;
  margin-right: $right + px !important;
}

@for $i from 0 to 100 {
  .m-#{$i} {
    @include mt($i);
    @include mr($i);
    @include ml($i);
    @include mb($i);
  }
  .mt-#{$i} {
    @include mt($i);
  }
  .mr-#{$i} {
    @include mr($i);
  }
  .mb-#{$i} {
    @include mb($i);
  }
  .ml-#{$i} {
    @include ml($i);
  }
  .mlr-#{$i} {
    @include ml($i);
    @include mr($i);
  }
  .mtb-#{$i} {
    @include mt($i);
    @include mb($i);
  }
}

// custom fontsize
@mixin fontsize($size) {
  font-size: $size + px;
}

@for $i from 8 to 30 {
  .fontsize-#{$i} {
    @include fontsize($i);
  }
}

@for $i from 8 to 30 {
  .heading-#{$i} {
    @include fontsize($i);
    font-weight: bold;
  }
}

.formLogin .ant-form-item-label > label {
  font-weight: bold;
  color: #00000080;
}

.linkText:hover {
  text-decoration: underline;
}

.btn-40 {
  padding: 8px 15px !important;
  border-radius: 5px !important;
  height: auto !important;
}

.c-body {
  max-height: calc(100vh - 50px);
}

.c-footer {
  display: none;
}

.logo--white {
  color: $white;
}

.logo--primary {
  color: $dark-blue;
}

.ant-btn-primary {
  color: $white;
  background-color: $dark-blue !important;
  border-color: $dark-blue !important;
}

.ant-btn-default {
  color: $dark-blue !important;
  border-color: $dark-blue !important;
}

.buttonDefault {
  display: flex !important;
  align-items: center;
}

.font-bold {
  font-weight: $font-weight-bold;
}

// start tab
.nav-tabs.navTab {
  display: flex !important;
  width: 100%;
  margin-bottom: 18px;
  background: transparent;
  border: none;
  flex-wrap: nowrap;
  overflow-x: auto;
  li {
    width: calc(100% / 9);
    min-width: 180px;
    &:not(:last-child) {
      .nav-link {
        margin-right: 7px;
      }
    }
  }
  .nav-link {
    border: 1px solid $periwinkle !important;
    background: $whisper;
    text-align: center;
    &.active {
      background: $color-active !important;
      color: white;
      border: 1px solid $color-active !important;
    }
  }
}

// end tab
// start table
.table td {
  vertical-align: middle;
  h2 svg {
    vertical-align: middle !important;
  }
}

.tbList.table {
  background: white;
  min-width: 1000px;
  td {
    min-width: 80px;
    max-width: 200px;
  }
}

.c-body .c-main table.table tr {
  white-space: normal;
}

table.table thead {
  white-space: normal !important;
}

// end table
// start checkbox
.form-check-input {
  height: 20px;
  width: 20px;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.BtnAdd {
  background: $dark-blue;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    color: white;
  }
}

.icon-18 {
  height: 18px;
}

.c-avatar {
  background: white;
  width: 30px;
  height: 30px;
  .c-avatar-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.linkDetail {
  color: $blue;
  text-decoration: underline;
  cursor: pointer;
}

// start modal
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 690px;
    width: 100%;
  }
}

.btnModal {
  max-width: 200px;
  width: 100%;
  padding: 9px 10px;
  border: 1px solid $dark-blue;
  font-weight: bold;
  margin-top: 10px;
  &:hover {
    opacity: 0.9;
  }
}

.btnModal.bgBue1 {
  background: $dark-blue;
  color: white;
}

.btnModal.bgBorder {
  background: white;
  color: $dark-blue;
}

// end modal
.link {
  color: $blue-link;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.img-scaledown {
  display: block;
  object-fit: scale-down;
  height: 100%;
  width: 100%;
}

.img-cover {
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.SearchContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SearchContainer .ant-form-item .ant-select {
  min-width: 150px !important;
}

.ps__rail-y {
  border-radius: 6px;
  width: 6px !important;
  .ps__thumb-y {
    right: 0 !important;
  }
}

.ps .ps__rail-y:hover {
  background: transparent;
  .ps__thumb-y {
    width: 6px;
    right: 0 !important;
  }
}

.status-active {
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #53cfea;
    top: 50%;
    margin-right: 4px;
  }
}

.status-inactive {
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    width: 7px;
    height: 7px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: $grey1;
    top: 50%;
    margin-right: 4px;
  }
}

// .ant-form-item-label {
//     width: 100%;
//     text-align: left!important;
//     font-weight: 700;
//     > label {
//         margin-bottom: 0;
//     }
// }
.textAreaCustom {
  resize: none;
  height: 95px !important;
}

.btn-back {
  min-width: unset !important;
}

.placeholder_bold {
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.ant-image-preview-operations {
  display: none !important;
}

.table {
  word-break: keep-all;
}

.table-faq {
  background-color: #ffffff !important;
  word-break: unset;
}

.logo-text-auth {
  filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(260deg) brightness(106%) contrast(101%);
  margin-left: 8px;
}

////////////////////////
//FORM SEARCH
.form-search {
  display: flex;
  flex-direction: row;
  .ant-form-item:not(:last-child) {
    margin-right: 8px;
  }
  &__input {
    flex: 0 0 250px;

    .ant-input-group-addon {
      display: none;
    }
  }
  &__select {
    flex: 0 0 200px;
  }
  @include respond(phone) {
    flex-direction: column;
    &__input,
    &__select {
      width: 100%;
      flex-basis: 0;
    }
  }
}

.ant-picker-time-panel-column {
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #c4c4c4;
  }
}

.search_add_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.search-form {
  display: flex;
  flex: 1;
  &__input {
    flex: 0 1 250px;
  }
  &__select {
    flex: 0 1 200px;
  }
  &__select-multi {
    flex: 0 1 300px;
  }
  &__date {
    flex: 0 1 300px;
  }
  .ant-form-item {
    margin-right: 8px;
  }
  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    &__input,
    &__select,
    &__date {
      width: 100%;
      flex-basis: 0;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    &__input,
    &__select,
    &__date {
      flex: 1;
    }
  }
}

svg {
  vertical-align: baseline;
}

.table-config {
  tbody tr:hover {
    cursor: pointer;
  }
}
